<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
            @click="onDownloadRuangan"
            :loading="printDispoLoadingRuangan"
            :disabled="printDispoLoadingRuangan"
            class="btn btn-sm btn-success"
            title="Print"
          >
            <i
              v-if="printDispoLoadingRuangan"
              class="fa fa-spinner fa-pulse fa-fw mr-1"
            ></i>
            <span
              v-if="!printDispoLoadingRuangan"
              class="badge badge-pill badge-light mr-1"
            >PDF</span>
            Ruangan
          </button>&nbsp;
          <button type="button" @click="goBack()" class="btn btn-inverse">
            Kembali
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Nama Kegiatan</small>
            <h6>
              {{
                state.detail.nama_kegiatan === "" ? "-" : state.detail.nama_kegiatan
              }}
            </h6>
            <small class="text-muxed">Tanggal Kegiatan</small>
            <h6>{{ tanggalkegiatan === "" ? "-" : tanggalkegiatan }}</h6>
            <small class="text-muted">Waktu Kegiatan</small>
            <h6>{{ state.detail.waktu_mulai ===  "" ? "-" : state.detail.waktu_mulai}} s/d {{ state.detail.waktu_akhir ===  "" ? "-" : state.detail.waktu_akhir}}</h6>
            <small class="text-muted">Nama Ruangan</small>
            <h6>{{ state.detail.nama_ruangan === "" ? "-" : state.detail.nama_ruangan }}</h6>
            <small class="text-muted">Unit Ruangan</small>
            <h6>{{ state.detail.name === "" ? "-" : state.detail.name }}</h6>
            <small class="text-muted">Kapasitas Ruangan</small>
            <h6>{{ state.detail.kapasitas_ruangan === "" ? "-" : state.detail.kapasitas_ruangan }}</h6>
            <small class="text-muted">Unit Pemesan</small>
            <h6>{{ state.detail.unit_pemesan === "" ? "-" : state.detail.unit_pemesan }}</h6>
            <small class="text-muted">Nama Pemesan</small>
            <h6>{{ state.detail.nama_penanggungjawab === "" ? "-" : state.detail.nama_penanggungjawab }}</h6>
            <small class="text-muted">Kontak Person</small>
            <h6>{{ state.detail.kontak_person === "" ? "-" : state.detail.kontak_person }}</h6>
            <small class="text-muted">Jumlah Peserta</small>
            <h6>{{ state.detail.jumlah_peserta === "" ? "-" : state.detail.jumlah_peserta }}</h6>
          </div>
          <div class="col-md-6 pull-left">
            <small class="text-muted">Catatan / Keterangan</small>
            <h6>{{ state.detail.keterangan === "" ? "-" : state.detail.keterangan }}</h6>
          </div>

          
        </div>
      </div>

      <hr />

    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import ImageItem from "../../../components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    // ImageItem
  },
  data() {
    return {
      printDispoLoadingRuangan: false,
    };
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.rekapPesan;
    },
    tanggalkegiatan() {
      return this.formatDate(this.state.detail.tanggal_kegiatan);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("rekapPesan/STATE", state);
      this.$store.dispatch("rekapPesan/getRekapPesanById", this.$route.params);
    },
    onDownloadRuangan() {
      
      var documentDefinition = {};
      const user = JSON.parse(localStorage.getItem("user"));
        documentDefinition = {
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "Lembar Ruangan",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: [150, 100, 100, 130],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Nama Ruangan",
                            bold: true,
                          },
                          {
                            text: this.state.detail.nama_ruangan,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Nama Kegiatan",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.nama_kegiatan,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Tanggal Kegiatan",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.tanggal_kegiatan,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Jumlah Peserta",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.jumlah_peserta,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "Unit / OPD :",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.name,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "",
                            style: "headerP",
                          }
                        ]
                      ]
                    }
                  ],
                  [
                    {
                      colSpan: 4,
                      columns: [
                        [
                          {
                            text: "",
                            style: "headerP1",
                          }
                        ]
                      ]
                    }
                  ],
                ],
              },
            },
            // {
            //   style: "tableExample",
            //   color: "#444",
            //   table: {
            //     widths: [505],
            //     headerRows: 0,
            //     body: [
            //       [
            //         {
            //           text: "",
            //           alignment: "left",
            //           columns: [
            //             [
            //               {
            //                 text: " ",
            //                 bold: true,
            //               },
            //               {
            //                 text: " ",
            //                 style: "header3",
            //               },
            //               {
            //                 text: " ",
            //                 bold: true,
            //               },
            //               {
            //                 text: " ",
            //                 style: "header3",
            //               },
            //               {
            //                 text: " ",
            //                 bold: true,
            //               },
            //               {
            //                 text: " ",
            //                 style: "header3",
            //               },
            //             ],
            //           ],
            //         },
            //       ],
                 
            //     ],
            //   },
            // },
            // {
            //   text: "Yogyakarta, ...............................",
            //   style: "headerTitleRight",
            // },
            // {
            //   text: this.state.detail.penerima_surat,
            //   style: "headerTitleRight",
            // },
            // {
            //   text: " ",
            //   style: "headerTitleRight",
            // },
            // {
            //   text: " ",
            //   style: "headerTitleRight",
            // },
            // {
            //   text: " ",
            //   style: "headerTitleRight",
            // },
            // {
            //   text: " ",
            //   style: "headerTitleRight",
            // },
            // {
            //   text: this.state.detail.penandatangan_nama,
            //   style: "headerTitleRight",
            // },
            // {
            //   text: this.state.detail.penandatangan_nip,
            //   style: "headerTitleRight",
            // },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            headerTitleRight: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "right",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerP: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
            headerP1: {
              bold: false,
              fontSize: 10,
              color: "black",
              alignment: "center",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };

      pdfMake.createPdf(documentDefinition).print();
    },
    goBack() {
      this.$store.dispatch("rekapPesan/onCancel");
    },
  },
};
</script>
